exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exhibitions-index-js": () => import("./../../../src/pages/exhibitions/index.js" /* webpackChunkName: "component---src-pages-exhibitions-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moving-image-index-js": () => import("./../../../src/pages/moving-image/index.js" /* webpackChunkName: "component---src-pages-moving-image-index-js" */),
  "component---src-pages-moving-image-sanity-video-doc-slug-current-js": () => import("./../../../src/pages/moving-image/{sanityVideoDoc.slug__current}.js" /* webpackChunkName: "component---src-pages-moving-image-sanity-video-doc-slug-current-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sanity-page-slug-current-js": () => import("./../../../src/pages/{sanityPage.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-js" */),
  "component---src-pages-sanity-post-slug-current-js": () => import("./../../../src/pages/{sanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-post-slug-current-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

